var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tuoterekisteri-selaa" },
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "offCanvas",
          attrs: { width: "450", app: "", right: "", temporary: "" },
          model: {
            value: _vm.showOffCanvas,
            callback: function ($$v) {
              _vm.showOffCanvas = $$v
            },
            expression: "showOffCanvas",
          },
        },
        [
          _vm.loadingOffCanvas
            ? _c("Spinner")
            : _c("OffCanvasTuote", {
                on: {
                  "close-button-click": _vm.setShowOffCanvas,
                  valitseTuoteMuokattavaksi: _vm.avaaTuotteenMuokkaus,
                  valitseTuoteKopioitavaksi:
                    _vm.avaaUudenTuotteenLuominenKopioimalla,
                  valitseTuoteryhmaMuokattavaksi: _vm.avaaTuoteryhmanMuokkaus,
                },
                model: {
                  value: _vm.selectedItem.item,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedItem, "item", $$v)
                  },
                  expression: "selectedItem.item",
                },
              }),
        ],
        1
      ),
      _c("ViewSelaaTopBarBase", {
        ref: "topBar",
        attrs: {
          "pikahaun-placeholder-teksti": "Hae (koodi, ID)",
          "vuex-module-name": _vm.vuexModuleName,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("TransitionSlidePanel", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchPanelOpen,
                expression: "searchPanelOpen",
              },
            ],
            staticClass: "pa-1 primary darken-1 shadow--inset",
          },
          [
            _c("FormSearchTuote", {
              model: {
                value: _vm.searchTerms,
                callback: function ($$v) {
                  _vm.searchTerms = $$v
                },
                expression: "searchTerms",
              },
            }),
          ],
          1
        ),
      ]),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": _vm.$t("common:product.ofProduct"),
          "lataa-csv-btn-config": {
            csvData: _vm.csvData,
            csvLabels: _vm.csvLabels,
            csvTitle: _vm.csvTitle,
          },
          "alt-selaa-btn-config": {
            toName: "tuoterekisteri-tuoteryhma-selaa",
            itemPartitiivi: _vm.$t("common:productGroup.ofProductGroups"),
          },
          "uusi-btn-config": {
            itemName: "tuote",
            click: _vm.avaaUudenTuotteenLuominen,
          },
          "tuo-aineistoa-btn-config": {
            click: function () {
              _vm.tuoCSVDialogAuki = true
            },
            text: _vm.$t("common:product.importProducts"),
          },
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: {
                      clickable: true,
                      data: item,
                      "selected-item": _vm.selectedItem.item,
                      fade: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSelectedItem({ item: item })
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.avaaMenuContext($event, item)
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: item.ikoni_ja_vari.ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": "open-in-new",
                                            "bg-color": item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSelectedItem({
                                                item: item,
                                              })
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common:product.showProduct")
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.tuoteryhma.nimi) + " "),
                    ]),
                    _c("td", { staticClass: "max-width" }, [
                      _vm._v(" " + _vm._s(item.koodi) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.selite) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatSumToFixed2")(item.ahinta)) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(" " + _vm._s(item.laatu) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(" " + _vm._s(item.alvtunnus.tunnus) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(" " + _vm._s(item.valuutta) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(" " + _vm._s(item.nykyinen_varastotilanne) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSumToFixed2")(item.hankintahinta)
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.tyhjaTaulukko
        ? _c("TyhjaTaulukkoOtsikko", {
            attrs: { otsikko: _vm.$t("product.youHaveNoProducts") },
          })
        : _vm._e(),
      _vm.tyhjaTaulukko
        ? _c("ToolbarTyhjaTaulukko", {
            attrs: {
              ikoni: "library_add",
              taustavari: "success",
              teksti: _vm.$t("product.createNewProduct"),
              infoteksti: _vm.$t("product.noProductAddInfo"),
              naytaAina: true,
            },
            on: { click: _vm.avaaUudenTuotteenLuominen },
          })
        : _vm._e(),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaTuotteenMuokkaus: _vm.avaaTuotteenMuokkaus,
          avaaTuoteryhmanMuokkaus: _vm.avaaTuoteryhmanMuokkaus,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            title:
              _vm.yleinen.muokattavaTuote.id &&
              _vm.yleinen.muokattavaTuote.id < _vm.getMaksimiTuoteId
                ? _vm.$t("common:product.editProduct")
                : _vm.$t("common:product.newProduct"),
            "dialog-auki": _vm.tuoteDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
          },
          on: {
            close: function ($event) {
              _vm.tuoteDialogAuki = false
            },
          },
        },
        [
          _c("FormTuote", {
            attrs: { "on-auki": _vm.tuoteDialogAuki },
            on: {
              close: _vm.suljeTuote,
              tuoteTallennettu: _vm.kasitteleTuotteenTallennus,
              uusiTuoteryhma: _vm.avaaTuoteryhmanMuokkaus,
              uusiTuoteryhmaSelaaNaytolta: _vm.avaaTuoteryhmanMuokkaus,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.tuoteryhmaTitle,
            "dialog-auki": _vm.tuoteryhmaDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
          },
          on: {
            close: function ($event) {
              _vm.tuoteryhmaDialogAuki = false
            },
          },
        },
        [
          _c("FormTuoteryhma", {
            attrs: {
              "on-auki": _vm.tuoteryhmaDialogAuki,
              "muokattava-tuoteryhma": _vm.muokattavaTuoteryhma,
            },
            on: {
              close: _vm.suljeTuoteryhma,
              tuoteryhmaTallennettu: _vm.kasitteleTuoteryhmanTallennus,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:fileHandling.bringFromCsvFile"),
            "dialog-auki": _vm.tuoCSVDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
            "tooltip-info-teksti": _vm.TuoteCSVInfo,
          },
          on: {
            close: function ($event) {
              _vm.tuoCSVDialogAuki = false
            },
          },
        },
        [
          _c("FormTuoteCSV", {
            attrs: { "on-auki": _vm.tuoCSVDialogAuki },
            on: {
              close: function ($event) {
                _vm.tuoCSVDialogAuki = false
              },
              "tuotteita-tallennettu": function ($event) {
                return _vm.resetoiJaAsetaSearchQuery("")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }