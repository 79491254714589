<template>
  <div class="tuoterekisteri-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasTuote
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @valitseTuoteMuokattavaksi="avaaTuotteenMuokkaus"
        @valitseTuoteKopioitavaksi="avaaUudenTuotteenLuominenKopioimalla"
        @valitseTuoteryhmaMuokattavaksi="avaaTuoteryhmanMuokkaus"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      ref="topBar"
      pikahaun-placeholder-teksti="Hae (koodi, ID)"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <FormSearchTuote v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      :item-partitiivi="$t('common:product.ofProduct')"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :alt-selaa-btn-config="{ toName: 'tuoterekisteri-tuoteryhma-selaa', itemPartitiivi: $t('common:productGroup.ofProductGroups') }"
      :uusi-btn-config="{ itemName: 'tuote', click: avaaUudenTuotteenLuominen }"
      :tuo-aineistoa-btn-config="{click: () => { tuoCSVDialogAuki = true }, text: $t('common:product.importProducts')}"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          @click="setSelectedItem({ item })"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="setSelectedItem({ item })"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:product.showProduct') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.tuoteryhma.nimi }}
          </td>
          <td class="max-width">
            {{ item.koodi }}
          </td>
          <td class="text-no-wrap">
            {{ item.selite }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap">
            {{ item.ahinta | formatSumToFixed2 }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.laatu }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.alvtunnus.tunnus }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.valuutta }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.nykyinen_varastotilanne }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.hankintahinta | formatSumToFixed2 }}
          </td>
        </TableRow>
      </template>
    </v-data-table>
          
    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('product.youHaveNoProducts')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('product.createNewProduct')"
      :infoteksti="$t('product.noProductAddInfo')"
      :naytaAina="true"
      @click="avaaUudenTuotteenLuominen"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaTuotteenMuokkaus="avaaTuotteenMuokkaus"
      @avaaTuoteryhmanMuokkaus="avaaTuoteryhmanMuokkaus"
      @update:return-value="suljeMenuContext"
    />

    <!-- Dialog: Uusi tuote / Muokkaa tuotetta -->
    <CardDialog
      :title="(yleinen.muokattavaTuote.id && yleinen.muokattavaTuote.id < getMaksimiTuoteId) ? $t('common:product.editProduct') : $t('common:product.newProduct')"
      :dialog-auki="tuoteDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      @close="(tuoteDialogAuki = false)"
    >
          <FormTuote
            :on-auki="tuoteDialogAuki"

            @close="suljeTuote"
            @tuoteTallennettu="kasitteleTuotteenTallennus"
            @uusiTuoteryhma="avaaTuoteryhmanMuokkaus"
            @uusiTuoteryhmaSelaaNaytolta="avaaTuoteryhmanMuokkaus"
          />
    </CardDialog>

    <!-- Dialog: Muokkaa tuoteryhmää -->
    <CardDialog
      :title="tuoteryhmaTitle"
      :dialog-auki="tuoteryhmaDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      @close="(tuoteryhmaDialogAuki = false)"
    >
          <FormTuoteryhma
            :on-auki="tuoteryhmaDialogAuki"
            :muokattava-tuoteryhma="muokattavaTuoteryhma"
            @close="suljeTuoteryhma"
            @tuoteryhmaTallennettu="kasitteleTuoteryhmanTallennus"
          />
    </CardDialog>

    <!-- Dialog: Tuo csv-tiedostosta -->
    <CardDialog
      :title="$t('common:fileHandling.bringFromCsvFile')"
      :dialog-auki="tuoCSVDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      :tooltip-info-teksti="TuoteCSVInfo"
      @close="tuoCSVDialogAuki = false"
    >
      <FormTuoteCSV
        :on-auki="tuoCSVDialogAuki"
        @close="tuoCSVDialogAuki = false"
        @tuotteita-tallennettu="resetoiJaAsetaSearchQuery('')"
      />
    </CardDialog>
  </div>
</template>

<script>

import TransitionSlidePanel from '@/components/TransitionSlidePanel'
import {
  FormSearchTuote,
  FormTuote,
  FormTuoteCSV,
  FormTuoteryhma,
  OffCanvasTuote,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersTuoterekisteri } from '@/utils/tableHeaders'
import { Tuote } from '@/class/Tuoterekisteri'
import { MaksimiTuoteId } from '@/utils/constants'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'TuoterekisteriSelaa',
  components: {
    FormSearchTuote,
    FormTuote,
    FormTuoteCSV,
    FormTuoteryhma,
    OffCanvasTuote,
    Pagination,
    TransitionSlidePanel,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      menuContextVaihtoehdot: [
        { icon: 'edit', title: 'Muokkaa tuotetta', event: 'avaaTuotteenMuokkaus'},
        { icon: 'edit', title: 'Muokkaa tuoteryhmää', event: 'avaaTuoteryhmanMuokkaus'},
      ],
      muokattavaTuoteryhma: {},
      tableHeaders: TableHeadersTuoterekisteri,
      tuoteDialogAuki: false,
      tuoteryhmaDialogAuki: false,
      tuoCSVDialogAuki: false,
    }
  },
  computed: {
    ...mapState([
    'yleinen',
    ]),
    TuoteCSVInfo () {
      return this.$t('infotekstit.tuoteCSVInfoTeksti')
    },
    csvLabels () {
      return {
        ...(!this.vainYksiPaamies && { paamies: { title: this.$t('common:creditor') } }),
        tuoteryhma: { title: this.$t('common:productGroup.productGroupHeader') },
        koodi: { title: this.$t('common:code') },
        selite: { title:  this.$t('common:definition') },
        id: { title:  this.$sovellusIdOtsake },
        ahinta: { title:  this.$t('common:unitPrice') },
        laatu: { title: this.$t('common:quantityUnit') },
        alvtunnus: { title: this.$t('common:vatP') },
        valuutta: { title: this.$t('common:currency') },
        varastotilanne: { title: this.$t('common:stockStatus') },
        hankintahinta: { title:  this.$t('common:purchasePrice') },
      }
    },
    csvData () {
      return this.items.map(item => {
        return {
          ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
          tuoteryhma: item.tuoteryhma.nimi,
          koodi: item.koodi,
          selite: item.selite,
          id: item.id,
          ahinta: this.$formatSum(item.ahinta),
          laatu: item.laatu,
          alvtunnus: item.alvtunnus.tunnus,
          valuutta: item.valuutta,
          varastotilanne: item.nykyinen_varastotilanne,
          hankintahinta: this.$formatSum(item.hankintahinta),
        }
      })
    },
    csvTitle () {
      return this.$t('common:product.productSearch') + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
    tuoteryhmaTitle () {
      if (this.muokattavaTuoteryhma && this.muokattavaTuoteryhma.id) {
        return this.$t('common:productGroup.editProductGroup')
      } 
      return this.$t('common:productGroup.addProductGroup')
    },
    getMaksimiTuoteId () {
      return MaksimiTuoteId
    },
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.tuoteryhma) query.append('tuoteryhma', this.searchTerms.tuoteryhma)
      if (this.searchTerms.koodi) query.append('koodi', this.searchTerms.koodi)
      if (this.searchTerms.selite) query.append('selite', this.searchTerms.selite)

      return query
    },
    kasitteleTuotteenTallennus (tallennettuTuote) {
      this.$naytaOnnistumisilmoitus(this.$t('common:product.productSaved'))

      const index = this.items.findIndex((item => item.id === tallennettuTuote.id))
      if (index >= 0) {
        // Päivitetään muokattu tuote listaukseen
        this.items.splice(index, 1, tallennettuTuote)
      } else {
        // Haetaan kaikki tulokset, kun ollaan tallennettu uusi tuote
        this.resetoiJaAsetaSearchQuery('')
      }

      this.yleinen.muokattavaTuote = {}
      this.$store.commit('yleinen/setMuokattavaTuote', this.yleinen.muokattavaTuote)
    },
    kasitteleTuoteryhmanTallennus (tallennettuTuoteryhma) {
      this.$naytaOnnistumisilmoitus(this.$t('common:productGroup.productGroupSaved'))

      // Päivitetään tuoteryhmä listauksen tuoteryhmän sisältäviin tuotteisiin. Ohitetaan haun
      // laukeaminen committaamalla mutaatio suoraan storeen.
      this.$store.commit(`${this.vuexModuleName}/setItems`, this.items.map(tuote => {
        if (tuote.tuoteryhma.id === tallennettuTuoteryhma.id) {
          tuote.tuoteryhma = tallennettuTuoteryhma
        }
        return tuote
      }))
      if (this.tuoteDialogAuki && this.yleinen.muokattavaTuote) {
        this.yleinen.muokattavaTuote.tuoteryhma = tallennettuTuoteryhma
        this.$store.commit('yleinen/setMuokattavaTuote', this.yleinen.muokattavaTuote)
      }
      this.muokattavaTuoteryhma = {}
    },
    avaaUudenTuotteenLuominen () {
      this.yleinen.muokattavaTuote = {}
      this.$store.commit('yleinen/setMuokattavaTuote', this.yleinen.muokattavaTuote)
      this.tuoteDialogAuki = true
    },
    avaaUudenTuotteenLuominenKopioimalla (tuote) {
      if (!tuote) tuote = this.menuContextItem
      this.yleinen.muokattavaTuote = new Tuote(_.cloneDeep(tuote))
      //laittamalla tuotteen id MaksimiTuoteId + tuote.id voidaan käyttää samoja toimintoja
      //kuin tuotetta muokatessa käytetään. Tästä on apua esim. päämiehen näyttämisessä FormTuote:ssa.
      //Tuotteen id alustetaan null:iksi, juuri ennen tuotteen tallennusta
      this.yleinen.muokattavaTuote.id = MaksimiTuoteId + tuote.id
      this.$store.commit('yleinen/setMuokattavaTuote', this.yleinen.muokattavaTuote)
      this.tuoteDialogAuki = true
    },
    avaaTuotteenMuokkaus (tuote) {
      // Jos eventin mukana ei tule tuotetta, tapahtuu avaaminen context menun kautta
      if (!tuote) tuote = this.menuContextItem

      this.yleinen.muokattavaTuote = tuote
      this.$store.commit('yleinen/setMuokattavaTuote', this.yleinen.muokattavaTuote)
      this.tuoteDialogAuki = true
    },
    suljeTuote () {
      this.tuoteDialogAuki = false
    },
    avaaTuoteryhmanMuokkaus (tuoteryhma) {
      // Jos eventin mukana ei tule tuoteryhmää, tapahtuu avaaminen context menun kautta
      if (!tuoteryhma && this.menuContextItem.tuoteryhma) tuoteryhma = this.menuContextItem.tuoteryhma

      this.muokattavaTuoteryhma = tuoteryhma
      if (!tuoteryhma && this.yleinen.muokattavaTuote) {
        this.$store.commit('user/setValittuPaamies',  this.yleinen.muokattavaTuote.paamies)
      }
      this.tuoteryhmaDialogAuki = true
    },
    suljeTuoteryhma () {
      this.tuoteryhmaDialogAuki = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

