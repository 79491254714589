<template>
  <div class="tuoterekisteri-tuoteryhma-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasTuoteryhma
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @valitseTuoteryhmaMuokattavaksi="avaaTuoteryhmanMuokkaus"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      ref="topBar"
      pikahaun-placeholder-teksti="Hae (nimi, ID)"
      :vuex-module-name="vuexModuleName"
      :search-panel="(false)"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="tuoteryhmää"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :alt-selaa-btn-config="{ toName: 'tuoterekisteri-selaa', itemPartitiivi: 'tuotteita' }"
      :uusi-btn-config="{ itemName: 'tuoteryhmä', click: avaaUudenTuoteryhmanLuominen }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
        pageText: '{0}-{1} / {2}',
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          @click="setSelectedItem({ item })"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="setSelectedItem({ item })"
                />
              </template>
              <template v-slot:default>
                <span>Näytä tuoteryhmä</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.nimi }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('productGroup.youHaveNoProductGroups')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('productGroup.createNewProductGroup')"
      :infoteksti="$t('productGroup.noProductGroupAddInfo')"
      :naytaAina="true"
      @click="avaaUudenTuoteryhmanLuominen"
    >
    </ToolbarTyhjaTaulukko>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaTuoteryhmanMuokkaus="avaaTuoteryhmanMuokkaus"
      @update:return-value="suljeMenuContext"
    />

    <!-- Dialog: Uusi tuoteryhmä / Muokkaa tuoteryhmää -->
    <CardDialog
      :title="muokattavaTuoteryhma.id ? 'Muokkaa tuoteryhmää' : 'Uusi tuoteryhmä'"
      :dialog-auki="tuoteryhmaDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      @close="(tuoteryhmaDialogAuki = false)"
    >
      <FormTuoteryhma
        :on-auki="tuoteryhmaDialogAuki"
        :muokattava-tuoteryhma="muokattavaTuoteryhma"
        @close="(tuoteryhmaDialogAuki = false)"
        @tuoteryhmaTallennettu="kasitteleTuoteryhmanTallennus"
      />
    </CardDialog>
  </div>
</template>

<script>

import {
  FormTuoteryhma,
  OffCanvasTuoteryhma,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersTuoteryhma } from '@/utils/tableHeaders'

export default {
  name: 'TuoterekisteriTuoteryhmaSelaa',
  components: {
    FormTuoteryhma,
    OffCanvasTuoteryhma,
    Pagination,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      menuContextVaihtoehdot: [
        { icon: 'edit', title: 'Muokkaa tuoteryhmää', event: 'avaaTuoteryhmanMuokkaus'},
      ],
      muokattavaTuoteryhma: {},
      tableHeaders: TableHeadersTuoteryhma,
      tuoteryhmaDialogAuki: false,
    }
  },
  computed: {
    csvLabels () {
      return {
        ...(!this.vainYksiPaamies && { paamies: { title: 'Päämies' } }),
        nimi: { title: 'Nimi' },
        id: { title:  this.$sovellusIdOtsake },
      }
    },
    csvData () {
      return this.items.map(item => {
        return {
          ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
          nimi: item.nimi,
          id: item.id,
        }
      })
    },
    csvTitle () {
      return 'Tuoteryhmät_haku-' + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    }
  },
  methods: {
    kasitteleTuoteryhmanTallennus (tallennettuTuoteryhma) {
      this.$naytaOnnistumisilmoitus('Tuoteryhmä tallennettu!')

      const index = this.items.findIndex((item => item.id === tallennettuTuoteryhma.id))
      if (index >= 0) {
        // Päivitetään muokattu tuoteryhmä listaukseen
        this.items.splice(index, 1, tallennettuTuoteryhma)
      } else {
        // Haetaan kaikki tulokset, kun ollaan tallennettu uusi tuoteryhmä
        this.resetoiJaAsetaSearchQuery('')
      }

      this.muokattavaTuoteryhma = {}
    },
    avaaTuoteryhmanMuokkaus (tuoteryhma) {
      // Jos eventin mukana ei tule tuoteryhmää, tapahtuu avaaminen context menun kautta
      if (!tuoteryhma) tuoteryhma = this.menuContextItem

      this.muokattavaTuoteryhma = tuoteryhma
      this.tuoteryhmaDialogAuki = true
    },
    avaaUudenTuoteryhmanLuominen () {
      this.muokattavaTuoteryhma = {}
      this.tuoteryhmaDialogAuki = true
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
